export const prefix = `${(process.env.NEXT_PUBLIC_ASSET_PREFIX || '').replace(/\/$/, '')}/`;

export const s3AssetURL = (path: string) => {
  const prefix = (process.env.NEXT_PUBLIC_S3_ASSET_PREFIX || '').replace(/\/$/, '');
  return `${prefix}/${path.replace(/^\//, '')}`;
};

export const curriculumImageURL = (slug: string) => {
  return s3AssetURL(`/curriculum-images/${slug}-top.png`);
};
